import { Fragment } from "react";

import { HomePageTags }       from "_common/seo";
import { initialValidations } from "_common/ssrWrapper";
import {
	mapBroadcastSessionProps
} from "_common/utils/broadcast";
import HomeRoute                  from "_components/route/home";
import { formatTabsWithFeedList } from "_components/route/homeAPIs";
import {
	getCarouselSessions
} from "_services/api/broadcast";
import {
	getPersonalizedFeedDataV2,
	getSEOTags
} from "_services/api/feed";

function Home ( props ) {

	return (
		<Fragment>

			<HomePageTags
				endpoint = { props?.currentPath }
				seoTags  = { props?.seoTags }
			/>

			<HomeRoute
				carousel         = { props?.carousel || [] }
				tabsWithFeedList = { props?.tabsWithFeedList || [] }
			/>
		</Fragment>
	);
}

const getServerSideProps = async context => initialValidations ( context, async defaultProps => {

	let seoTags, pageName = "homePage";

	try {
		const results = await Promise.allSettled (
			[
				getCarouselSessions.bind ( defaultProps ) ( { pageSize: 10 } ),
				getSEOTags.bind ( defaultProps ) ( { pageName } ),
				getPersonalizedFeedDataV2.bind ( defaultProps ) ( { pageSize: 5, pageNo: 1 } )
				// getPopularStreamingGames.bind ( defaultProps ) ( { pageNo: 1, pageSize: 50, isDesktopGame: 0, sortByPriority: 1 } )
				// getShortVideos.bind ( defaultProps ) ( { pageNo: 1, pageSize: 6 } )
			]
		);

		let sessions    = [], tabsWithFeedList = [];
		let forceLogout = false;

		results.forEach ( ( result, index ) => {
			if ( result.status === "fulfilled" ) {
				switch ( index ) {
					case 0:
						sessions = result.value;
						break;

					case 1:
						seoTags = result.value;
						break;

					case 2:
						tabsWithFeedList = result.value;
						break;

					/* case 3:
						gameList = result.value;
						break; */

					/* case 4:
						reelList = formatPersonalizedFeedData ( result.value );

						break; */

				}
			}

			if ( result.status === "rejected" && result.reason?.message === "TOKEN_EXPIRED_FORCE_LOGOUT" )
				forceLogout = true;
		} );

		if ( forceLogout )
			return {
				redirect: {
					destination : `/logout?forceLogout=${ true }&prevLocation=${ defaultProps?.currentPath }`,
					permanent   : false
				}
			};

		const formattedFeed = formatTabsWithFeedList ( tabsWithFeedList );

		const carousel = sessions.slice ( 0, 4 ).map ( session => {
			return { ...mapBroadcastSessionProps ( session ), session };
		} );
		const seo      = seoTags?.seo_tags ? seoTags.seo_tags : [];

		if ( !defaultProps?.isLoggedIn && carousel.length && formattedFeed.length ) {
			context.res.setHeader (
				"Cache-Control",
				"public, s-maxage=90, stale-while-revalidate=120"
			);
		}

		return {
			props: {
				...defaultProps,
				carousel         : carousel,
				seoTags          : seo,
				tabsWithFeedList : formattedFeed
			}
		};
	} catch ( error ) {
		return {
			props: {
				...defaultProps,
				carousel         : [],
				gameList         : [],
				seoTags          : [],
				reelList         : [],
				tabsWithFeedList : []
			}
		};
	}

}, { blockOBS: true } );

export { getServerSideProps };

export default Home;
