import dynamic                 from "next/dynamic";
import { Fragment, useEffect } from "react";
import useSWR                  from "swr";

import { useGetAdConfig, useGetDeviceType } from "_common/hooks/global";
import { enableBackupAPIsInCookies }        from "_common/utils";
import Render                               from "_components/atoms/render";
import HomePage                             from "_components/pages/home";
import ResponsiveCarousel                   from "_components/templates/carousel";

import {
	// fetcher1,
	// fetcher2,
	fetcher3,
	fetcher4,
	fetcher5
} from "./homeAPIs";

const TournamentBanner = dynamic ( () => import ( "_components/pages/home/tournamentBanner" ), { ssr: false } );
const AdSlot           = dynamic ( () => import ( "_components/features/ads/slot" ), { ssr: false } );

const swrConfig = {
	revalidateIfStale     : false,
	revalidateOnFocus     : false,
	revalidateOnReconnect : false
};

const HomeRoute = props => {

	// const { data: carousel } = useSWR ( "rooter-homepage-1", fetcher1, swrConfig );
	// const { data: tabData }                = useSWR ( "rooter-homepage-2", fetcher2, swrConfig );
	const { data: gameList }               = useSWR ( "rooter-homepage-3", fetcher3, swrConfig );
	const { data: reelList }               = useSWR ( "rooter-homepage-4", fetcher4, swrConfig );
	const { data: backuptabsWithFeedList } = useSWR ( "rooter-homepage-5", fetcher5, swrConfig );

	const tabsWithFeedList = backuptabsWithFeedList?.length ? backuptabsWithFeedList : props?.tabsWithFeedList;

	useEffect ( () => {
		if ( props?.tabsWithFeedList?.length )
			return;

		enableBackupAPIsInCookies ();
	}, [] );

	const trendingFeedList  = tabsWithFeedList.filter ( item => parseInt ( item.feedTypeId ) === -1 )[0];
	const proFeedList       = tabsWithFeedList.filter ( item => item.feedTypeId.toString () === "693856" )[0];
	const remainingFeedList = tabsWithFeedList.filter ( item => {
		return parseInt ( item.feedTypeId ) !== -1 && item.feedTypeId.toString () !== "693856";
	} );

	const deviceType                   = useGetDeviceType ();
	const isMobile                     = deviceType === "mobile";
	const { HOME_BANNER_MWEB: config } = useGetAdConfig ();

	return (
		<Fragment>

			<Render condition = { props?.carousel?.length }>
				<ResponsiveCarousel data = { props?.carousel } />
			</Render>

			<Render condition = { isMobile && config?.enabled }>
				<div style = { { display: "flex", justifyContent: "center" } }>
					<AdSlot
						id   = { config?.adUnitId }
						path = { config?.slotId }
						size = { config?.size }
					/>
				</div>

			</Render>

			<Render condition = { !isMobile }>
				<TournamentBanner />
			</Render>

			<HomePage
				allTabsWithFeedList = { isMobile ? tabsWithFeedList : ( remainingFeedList || [ [] ] ) }
				gameList            = { gameList || [] }
				isMobile            = { isMobile }
				proFeedList         = { proFeedList }
				reelList            = { reelList }
				trendingFeedList    = { trendingFeedList }
			/>

		</Fragment>
	);
};

export default HomeRoute;
