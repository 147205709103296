import {
	formatPersonalizedFeedData,
	mapBroadcastSessionProps
} from "_common/utils/broadcast";
import {
	getCarouselSessions,
	getPopularStreamingGames
} from "_services/api/broadcast";
import {
	getPersonalizedFeedDataV2,
	getShortVideos
} from "_services/api/feed";

export const formatTabsWithFeedList = ( data = [] ) => {

	return data.map ( item => ( {
		display_name : item.tabName,
		feedList     : formatPersonalizedFeedData ( item.mixedFeedAndBroadcast ),
		feedTypeId   : item.tabId
	} ) );
};

export const formatGameList = ( list = [] ) => {

	return list.map ( game => ( {
		image              : game?.image,
		liveStreamsCount   : game?.currentStreamCount,
		totalStreamsCount  : game?.totalStreams,
		name               : game?.name,
		id                 : game?.id,
		androidPackageName : game.androidPackageName,
		banner             : game.banner
		// appUrl: game.appUrl
	} ) );
};

export const fetcher1 = async () => {
	const sessions = await getCarouselSessions ( { pageSize: 10 } );

	return sessions.slice ( 0, 4 ).map ( session => {
		return { ...mapBroadcastSessionProps ( session ), session };
	} );
};

export const fetcher2 = async () => {
	const tabsWithFeedList = await getPersonalizedFeedDataV2 ( { pageSize: 5, pageNo: 1 } );

	return formatTabsWithFeedList ( tabsWithFeedList );
};

export const fetcher3 = async () => {
	const gameList = await getPopularStreamingGames ( { pageNo: 1, pageSize: 50, isDesktopGame: 0, sortByPriority: 1 } );

	return formatGameList ( gameList );
};

export const fetcher4 = async () => {
	const result = await getShortVideos ( { pageNo: 1, pageSize: 6 } );

	return formatPersonalizedFeedData ( result ).slice ( 0, 6 );
};

export const fetcher5 = async () => {
	const result = await getPersonalizedFeedDataV2 ( { pageNo: 1, pageSize: 10 } );

	return formatTabsWithFeedList ( result );
};
