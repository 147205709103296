import dynamic            from "next/dynamic";
import { isValidElement } from "react";
import useSWR             from "swr";

import { getSearchResults } from "_services/api/shop";

const Desktop = dynamic ( () => import ( "./desktop" ) );
const Mobile  = dynamic ( () => import ( "./mobile" ) );

const fetcher = async () => {
	const res = await getSearchResults ( { pageNo: 1, pageSize: 6 } );

	return res.items;
};

const HomePage = props => {
	const { data, isValidating } = useSWR (
		"shop-products",
		fetcher,
		{
			revalidateIfStale     : false,
			revalidateOnFocus     : false,
			revalidateOnReconnect : false
		}
	);

	if ( props?.isMobile )
		return (
			<Mobile
				{ ...props }
				loading  = { isValidElement }
				shopData = { data }
			/>
		);

	return (
		<Desktop
			loading  = { isValidating }
			shopData = { data }
			{ ...props }
		/>
	);

};

export default HomePage;
