import xhr         from "_common/xhr";
import { configs } from "_config/index";

/**
 * Gets the search result for a query
 *
 * @async
 * @param {Object} param
 * @param {string} param.query mandatory, words to search for in the product's fields
 * @param {string} [ param.categoryName ] category name to filter on format: from:to (both inclusive)
 * @param {'relevance' | 'price'} [ param.sortBy ] possible values: ['relevance', 'price']
 * @param {'asc' | 'desc'} [ param.sortOrder ] possible values: ['asc', 'desc']
 * @param {string} [ param.priceRange ] format: from:to (both inclusive)
 * @param {number} param.pageNo min: 20, max: 100
 * @param {number} param.pageSize min: 1, default: 1
 *
 * @returns {Promise<any>}
 */
export async function getSearchResults ( {
	query,
	pageNo,
	pageSize,
	categoryName,
	sortBy,
	sortOrder,
	priceRange
} ) {

	const result = await xhr.get.bind ( this ) ( {
		url    : `${ configs.SHOP_URL }listings/products`,
		params : {
			query,
			pageNo,
			pageSize,
			categoryName,
			sortBy,
			sortOrder,
			priceRange
		}
	} );

	return result;
}
